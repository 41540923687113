<template>
  <section class="meniu fixed wh100 left0 top0 z99 flex-center">
    <ul>
      <li v-for="(item, key) in meniu"
          :key="key"
          @click="selectItem(key)">
        {{ item }}
      </li>
    </ul>
  </section>
</template>

<script>
  export default {
    name: 'Meniu',
    data () {
      return {
        meniu: {
          intro: 'Introducere',
          live: 'Sesiuni live',
          despre: 'Despre noi',
          albume: 'Albume / Lansări',
          stories: 'Povești',
          contact: 'Contactează-ne'
        }
      }
    },
    methods: {
      selectItem (section) {
        this.$emit('goToSection', section);
        this.handleClose();
      },
      handleClose () {
        this.$emit('close');
      }
    }
  }
</script>

<style lang="scss">
  .meniu {
    @media only screen and (min-width: 0) {
    	background-color: black;
      li {
        font-size: 3.4rem;
        font-family: Barlow, sans-serif;
        color: white;
        padding: 13px 0;
      }
    }
  }
</style>
