<template>
  <div class="member-details fixed wh100 left0 top0 z99">
    <figure>
      <img class="w100" :src="require(`../assets/members/full/${member}.png`)" alt="Marina Pingulescu">
    </figure>
    <p>
      {{ details }}
    </p>
    <button class="uppercase"
            @click="handleClose">
      {{ close }}
    </button>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'MemberDetails',
    props: {
      member: {
        type: String,
        default: 'marina'
      },
      details: String
    },
    computed: {
      ...mapGetters(['close'])
    },
    methods: {
      handleClose () {
        this.$emit('close');
      }
    }
  }
</script>

<style lang="scss">
  @import "../styles/var";
  .member-details {
    @media only screen and (min-width: 0) {
      background-color: black;
      overflow-y: scroll;
      p {
        color: lightgray;
        font-size: 1.8rem;
        text-align: left;
        padding: 40px 16px 86px;
        line-height: 3rem;
      }
      button {
        margin-bottom: 40px;
        color: $purple;
        background: transparent;
        font-size: 1.5rem;
        letter-spacing: 0.214rem;
        border: none;
        font-family: Roboto, sans-serif;
        font-weight: 900;
      }
    }

    @media only screen and (min-width: 1024px) {
      width: 680px;
      margin: 0 auto;
    	img {
        width: 400px;
        padding-top: 44px;
      }
      p {
        width: 600px;
        margin: 0 auto;
        text-align: center;
      }
    }
  }
</style>
