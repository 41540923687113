import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import * as youtube from './modules/youtube'
import axios from "axios"
import api from "@/store/api"
// axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    languages: ['en', 'ro'],
    lang: 'en',
    templates: {
      en: {
        meniu: {
          intro: 'Introduction',
          live: 'Live sessions',
          despre: 'About us',
          albume: 'Albums / Releases',
          stories: 'Stories',
          contact: 'Contact us'
        },
        home: {
          title: 'Classical sounds in a contemporary key signature',
          contactUsBtn: 'CONTACT US',
          notifBox: {
            title: 'New album released',
            text: `Dear friends, we are starting October with lightning news. <br />
                  This is not Black Friday, but Our Happy Friday: today, October 1,
                  we launch in the Internet Ocean our bottle with the message:
                  <br>
                  <b>"Now and Then, new sounds from an old world"</b>.
                  <br>
                  It's the title of our third album, produced by the Berlin label <br>
                  The Lollipop Shop.
                  <br> Gaudeamus igitur!`,
            listenLink: 'listen full album / buy here',
            reviews: {
              title: 'Album Reviews'
            }
          },
          liveSessions: {
            title: 'live sessions'
          },
          about: {
            title: 'about us',
            text: `We are a musical group of Bucharest improvisers, founded in 2015.
                    Our music combines acoustic and electric instruments
                    (violin, guitar, wind and percussion instruments)
                    with electronic equipment operated in real time.
                    <br/><br/>
                    We have developed various approaches and projects, sometimes in collaboration with other artists:
                    audio recordings, live illustration for some silent movies, completion of some
                    fine art events, folklore processing in a current language,
                    the inclusion in our programs of oriental improvisation or a
                    the Byzantine religious melody.`,
            core: {
              title: 'core members'
            },
            members: [
              {
                name: 'Marina Pingulescu',
                details: 'violin, voice, tibetan bowls',
                fullDetails: 'Marina is a violinist with a master\'s degree in musical performance from the University of Music in Bucharest. Her classical history has helped her collaborate with various orchestral and chamber music ensembles, singing in Germany, France, Spain, Turkey and Norway. He also conducted the string orchestra with Pink Martini at the IMAP Festival in 2017.',
                img: 'marina'
              },
              {
                name: 'Călin Torsan',
                details: 'wind instruments, voice',
                fullDetails: `Călin Torsan - self-taught blower, founder and member of several Bucharest groups, writer.`,
                img: 'calin'
              },
              {
                name: 'Mihai Balabaș',
                details: 'live sampling, electronic, electric guitar',
                fullDetails: `Mihai Balabaș - studied violin at the “Dinu Lipatti” high school, then at the National University of Music (both in Bucharest), continuing his studies with a master's degree in the same field. Together with the band Bucium he sang in the opening concert that Bob Dylan gave in the Romanian capital, and during the Bucharest festival "Jazz in Church" he sang with the Swiss pianist Nik Bärtsch (in 2012), but also in the opening of the Norwegian trumpeter Nils Peter Molvaer (in 2014).`,
                img: 'mihai'
              },
              {
                name: 'Vasile Gherghel',
                details: 'live sampling, electronic, vocals, bass guitar',
                fullDetails: `Vasile Gherghel - concerned with electronic musical instruments and the confluence between them and the sound, the mechanical vibration of the classical ones.`,
                img: 'vasile'
              },
              {
                name: 'Marius Achim',
                details: 'drums, percussion',
                fullDetails: `Marius Achim - has collaborated with several Bucharest folk, rock and country formulas, appearing on several mainstream recordings and in television entertainment shows.`,
                img: 'marius'
              },
            ],
            details: 'details'
          },
          albums: {
            title: 'albums / releases',
            list: [
              {
                cover: 'now-and-then',
                details: `<b> Now and Then </b>
                          <br />
                          9 tracks, 68 minutes
                          <br />
                          released 01 December 2018`,
                link: `https://lollipoppeshoppe.bandcamp.com/album/now-and-then`,
                reviews: [
                  {
                    name: 'Musikansich (DE)',
                    link: 'https://www.musikansich.de/review.php?id=22497'
                  },
                  {
                    name: 'Disgues (BE)',
                    link: '/review-now-and-then-BE.pdf'
                  }
                ]
              },
              {
                cover: 'deeper',
                details: `<b> Deeper Kind of Yellow Blue </b>
                          <br />
                          11 tracks, 46 minutes
                          <br />
                          released 01 December 2018`,
                link: `https://thirstyleavesmusic.bandcamp.com/album/deeper-kind-of-yellow-blue`,
                reviews: [
                  {
                    name: 'Dilema Veche (RO)',
                    link: 'https://dilemaveche.ro/sectiune/muzica/articol/etnii-psihedelice'
                  }
                ]
              },
              {
                cover: 'zig-zag',
                details: `<b>ZIGZAG</b>
                          <br>
                          3 tracks, 32 minutes
                          <br>
                          released 01 December 2015`,
                link: 'https://thirstyleavesmusic.bandcamp.com/album/zigzag',
                reviews: [
                  {
                    name: 'SANTA SANGRE (POLAND)',
                    link: 'https://santasangremagazine.wordpress.com/2016/01/10/multumult-zigzag/'
                  }
                ]
              },
              {
                cover: 'long-path',
                details: `<b>The long path of the short way</b>
                          <br>
                          10 tracks, 69 minutes
                          <br>
                          released 30 April 2018`,
                link: 'https://multumult.bandcamp.com/album/the-long-path-of-the-short-way'
              }
            ],
            listen: 'listen'
          },
          stories: {
            title: 'stories',
            list: [
              {
                title: 'abuabua',
                text: 'The formation of the group was anticipated by the first Gherghel – Torsan collaboration, which surprised the two in different roles: Torsan, as a stage musician; Gherghel, as producer and sound engineer of the abuabua group. This is how the album A Handful of Grains (2015) was recorded and edited, inspired by the lullabies of different traditional cultures.'
              }
            ],
            more: 'see more'
          },
          contact: {
            title: 'contact us',
            text: 'For shows, productions, collaborations and events, or just to get to know us, please send us a message.',
            form: {
              name: 'name',
              email: 'email',
              msg: 'message',
              btn: 'send'
            },
            follow: {
              title: 'follow us',
              text: 'Do you want to know what else we did, where we sang before, we invite you to follow us on our official channels.'
            }
          },
          upBtn: 'up',
          messageSent: 'Thank you for contacting us! We will reply to you shortly.'
        },
        close: 'close'
      },
      ro: {
        meniu: {
          intro: 'Introducere',
          live: 'Sesiuni live',
          despre: 'Despre noi',
          albume: 'Albume / Lansări',
          stories: 'Povești',
          contact: 'Contactează-ne'
        },
        home: {
          title: 'Sunete clasice într-o cheie contemporană, mixate electronic live',
          contactUsBtn: 'CONTACTEAZĂ-NE',
          notifBox: {
            title: 'Lansare album nou',
            text: `Dragi prieteni, începem luna octombrie cu o veste trăsnet. <br>
            Nu-i vorba de Black Friday, ci de Our Happy Friday: azi, 1 octombrie,
            lansăm în Oceanul Internet sticluța noastră cu mesajul:
            <br>
            <b>"Now and Then, new sounds from an old world"</b>.
            <br>
            E titlul celui de-al treilea album al nostru, produs de labelul berlinez <br>
            The Lollipop Shop. <br> Gaudeamus igitur!`,
            listenLink: 'ascultă/cumpără aici',
            reviews: {
              title: 'Album Reviews'
            }
          },
          liveSessions: {
            title: 'sesiuni live'
          },
          about: {
            title: 'despre noi',
            text: `Suntem un grup muzical de improvizatori bucureșteni, înființat în anul 2015.
                    Muzica noastră combină instrumentele acustice și electrice
                    (vioara, chitara, instrumentele de suflat și percuție)
                    cu aparatura electronică manevrată în timp real.
                    <br>
                    <br>
                    Am dezvoltat preocupări și proiecte diverse, uneori în colaborare cu alți artiști:
                    înregistrări audio, ilustrare live pentru câteva filme mute, completarea unor
                    evenimente de artă plastică, prelucrări de folclor într-un limbaj actual,
                    includerea în programele noastre a improvizației de tip oriental sau a
                    melosului religios bizantin.`,
            core: {
              title: 'Nucleul actual al colectivului'
            },
            members: [
              {
                name: 'Marina Pingulescu',
                details: 'vioară, voce, boluri tibetane',
                fullDetails: 'Marina este violonist cu masterat în interpretare muzicală de la Universitatea de Muzică București. Istoricul ei clasic i-a ajutat colaborarea cu diferite ansambluri de orchestră și muzică de cameră, cântând în Germania, Franța, Spania, Turcia și Norvegia. De asemenea, a condus orchestra de coarde alături de Pink Martini la Festivalul IMAP în 2017.',
                img: 'marina'
              },
              {
                name: 'Călin Torsan',
                details: 'instrumente de suflat, voce',
                fullDetails: 'Călin Torsan – suflător autodidact, fondator și membru al câtorva grupuri bucureștene, scriitor. ',
                img: 'calin'
              },
              {
                name: 'Mihai Balabaș',
                details: 'live sampling, electronice, chitară electrică',
                fullDetails: 'Mihai Balabaș – a studiat vioara la liceul „Dinu Lipatti”, apoi în Universitatea Naţională de Muzică (ambele din Bucureşti), continuându-și studiile cu un master în acelaşi domeniu. Împreună cu trupa Bucium a cântat în deschiderea concertului pe care Bob Dylan l-a susținut în capitala României, iar în cadrul festivalului bucureștean „Jazz in Church” a cântat împreună cu pianistul elvețian Nik Bärtsch (în 2012), dar și în deschiderea trompetistului norvegian Nils Peter Molvaer (în 2014).',
                img: 'mihai'
              },
              {
                name: 'Vasile Gherghel',
                details: 'live sampling, electronice, voce, chitară bas',
                fullDetails: 'Vasile Gherghel - preocupat de instrumentele muzicale electronice  și de confluența dintre acestea și sunetul, vibrația mecanică a celor clasice.',
                img: 'vasile'
              },
              {
                name: 'Marius Achim',
                details: 'tone, percuție',
                fullDetails: 'Marius Achim – a colaborat cu mai multe formule bucureștene de folk, rock și country, apărând pe câteva înregistrări mainstream și în emisiuni televizate de divertisment.',
                img: 'marius'
              },
            ],
            details: 'detalii'
          },
          albums: {
            title: 'albume / lansări',
            list: [
              {
                cover: 'now-and-then',
                details: `<b> Now and Then </b>
                          <br />
                          9 tracks, 68 minutes
                          <br />
                          released 01 December 2018`,
                link: `https://lollipoppeshoppe.bandcamp.com/album/now-and-then`
              },
              {
                cover: 'deeper',
                details: `<b>Deeper Kind of Yellow Blue</b>
                          <br>
                          11 tracks, 46 minutes
                          <br>
                          released 01 December 2018`,
                link: `https://thirstyleavesmusic.bandcamp.com/album/deeper-kind-of-yellow-blue`
              },
              {
                cover: 'zig-zag',
                details: `<b>ZIGZAG</b>
                          <br>
                          3 tracks, 32 minutes
                          <br>
                          released 01 December 2015`,
                link: 'https://thirstyleavesmusic.bandcamp.com/album/zigzag'
              },
              {
                cover: 'long-path',
                details: `<b>The long path of the short way</b>
                          <br>
                          10 tracks, 69 minutes
                          <br>
                          released 30 April 2018`,
                link: 'https://multumult.bandcamp.com/album/the-long-path-of-the-short-way'
              }
            ],
            listen: 'ASCULTĂ'
          },
          stories: {
            title: 'POVEȘTI',
            list: [
              {
                title: 'abuabua',
                text: 'Formarea grupului multumult a fost anticipată de prima colaborare Gherghel–Torsan, care i-a surprins pe cei doi în roluri diferite: Torsan, ca muzician de scenă; Gherghel, ca producător și inginer de sunet al grupului abuabua. Așa a fost înregistrat și editat albumul O mână de boabe (2015), inspirat din cântecele de leagăn ale diferitelor culturi tradiționale.'
              }
            ],
            more: 'vezi mai multe'
          },
          contact: {
            title: 'Contactează-ne',
            text: 'Pentru spectacole, producții, colaborări și evenimente, sau doar pentru a ne cunoaște, te rugăm să ne trimiți un mesaj.',
            form: {
              name: 'nume',
              email: 'email',
              msg: 'mesaj',
              btn: 'trimite'
            },
            follow: {
              title: 'URMĂREȘTE-NE',
              text: 'Vrei să afli ce am mai facut, pe unde am mai cântat, te invităm să ne urmărești pe canalele noastre oficiale.'
            }
          },
          upBtn: 'Sus',
          messageSent: 'Vă mulțumim! Vă vom răspunde în scurt timp.'
        },
        close: 'ÎNCHIDE'
      }
    },
    messageSent: false,
    contactForm: {
      name: '',
      email: '',
      message: ''
    }
  },
  actions: {
    async send_msg ({state, commit}, observer) {
      try {
        await axios.post(api.contact, state.contactForm)
        state.messageSent = true
        commit('RESET_CONTACT_FORM')
        observer.reset()
        setTimeout(() => {
          state.messageSent = false
        }, 5000)
      } catch (err) {
        console.log(`error sending msg`, err)
      }
    }
  },
  mutations: {
    CHANGE_LANGUAGE (state, lang) {
      Vue.set(state, 'lang', lang)
    },
    RESET_CONTACT_FORM (state) {
      Object.keys(state.contactForm).forEach(key => {
        Vue.set(state.contactForm, key, '')
      })
    }
  },
  getters: {
    otherLanguages: state => {
      return state.languages.filter(lang => lang !== state.lang)
    },
    meniu: state => {
      return state.templates[state.lang].meniu
    },
    close: state => {
      return state.templates[state.lang].close
    },
    template: state => {
      const route = router.currentRoute.name
      return state.templates[state.lang][route]
    }
  },
  modules: {
    youtube
  }
})
