<template>
  <section class="stories fixed wh100 left0 top0 z99">
    <i class="fixed right0 top0" @click="handleClose">
      <svg width="80px" height="80px" viewBox="0 0 80 80" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="1-Mobile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Detalii-Artist" transform="translate(-295.000000, -58.000000)">
            <g id="Group" transform="translate(295.000000, 58.000000)">
              <rect id="Rectangle" fill="#000000" x="0" y="0" width="80" height="80"></rect>
              <g id="Group-2" transform="translate(25.064971, 24.064971)" stroke="#FFFFFF" stroke-width="4">
                <path d="M0.935028843,30.9350288 L31.8700577,0" id="Line-Copy-4"></path>
                <path d="M0.935028843,30.9350288 L31.8700577,0" id="Line-Copy-4" transform="translate(16.000000, 15.500000) scale(-1, 1) translate(-16.000000, -15.500000) "></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </i>

    <h2>
      POVEȘTI
    </h2>

    <article v-for="({ titlu, text }, index) in stories"
             :key="index">
      <h3>
        {{ titlu }}
      </h3>
      <p class="text-left">
        {{ text }}
      </p>
    </article>

    <button @click="handleClose">ÎNCHIDE</button>
  </section>
</template>

<script>
  export default {
    name: 'Stories',
    props: ['stories'],
    methods: {
      handleClose () {
        this.$emit('close');
      }
    }
  }
</script>

<style lang="scss">
  @import "../styles/var";
  .stories {
    @media only screen and (min-width: 0) {
      padding: 40px 0;
      background-color: black;
      overflow-y: scroll;
      h3 {
        margin-top: 50px;
        color: white!important;
        font-family: Barlow, sans-serif;
        font-size: 3.4rem!important;
        line-height: 4.2rem!important;
      }
      p {
        color: lightgray;
        padding-bottom: 44px;
      }
      button {
        margin-bottom: 40px;
        color: $purple;
        background: transparent;
        font-size: 1.5rem;
        letter-spacing: 0.214rem;
        border: none;
        font-family: Roboto, sans-serif;
        font-weight: 900;
      }
    }
  }
</style>
