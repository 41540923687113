import axios from 'axios';
import Vue from "vue";

export const state = function () {
  return {
    clips: []
  }
}

export const actions = {
  async get_utube_clips ({ state }) {
    try {
      const params = {
        part: 'snippet',
        maxResults: 50,
        key: 'AIzaSyA-IT-Wxc14sLFNdXrZIQU-39Aj00EVqjQ',
        playlistId: 'PLMsTw7TWkInOfbqqSYYBXVlbWhsk1vgeI'
      }
      const { data: { items } } = await axios('https://www.googleapis.com/youtube/v3/playlistItems', {params})
      const clips = items.map(({ snippet }) => {
        return {
          videoId: snippet.resourceId.videoId,
          title: snippet.description,
          img: snippet.thumbnails.standard.url
        }
      })
      Vue.set(state, 'clips', clips)
    }
    catch (err) {
      console.log(err.response.data)
    }
  }
}
