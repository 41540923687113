import Vue from 'vue';
import VueSmoothScroll from 'vue2-smooth-scroll';
import App from './App.vue';
import router from './router';
import store from './store';
import './styles/base.scss';
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
Vue.use(VueSmoothScroll);

extend('required', {
  ...required,
  message: 'Required'
});
extend('email', {
  ...email,
  message: 'Incorrect email format'
});

Vue.config.productionTip = false
export const EventBus = new Vue();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
