<template>
  <div class="home-page text-center"
       ref="intro"
       id="home-page">

    <transition name="modal-fade">
      <MemberDetails v-show="isMemberDetails"
                     :member="memberName"
                     :details="memberDetails"
                     @close="hideDetails()" />
    </transition>

    <transition name="modal-fade">
      <Stories v-show="isAllStories"
               :stories="stories"
               @close="hideDetails()" />
    </transition>

    <transition name="modal-slide-left">
      <nav v-show="isMenu"
           class="meniu-box fixed left0 top0 flex-center">
        <ul>
          <li v-for="(item, key) in meniu"
              :key="key"
              @click="goToSection(key)">
            {{ item }}
          </li>
          <li class="pointer flex-center">
            <img v-for="(lang, index) in otherLanguages"
                 :key="index"
                 @click="changeLanguage(lang)"
                 :src="require(`../assets/icons/${lang}-flag.jpg`)"
                 alt="language">
          </li>
        </ul>
      </nav>
    </transition>

    <main class="main-content">
      <header v-show="isMenuIcon"
              class="fixed w100 z99 pointer">
        <img class="settings-icon"
             :class="{'hide-settings-icon': isMenu}"
             src="../assets/settings-icon.svg"
             @click="showMenu()"
             alt="settings icon">
        <i class="close-menu-icon"
           :class="{'slide-close-icon': isMenu}"
           @click="hideDetails()">
          <svg width="80px" height="80px" viewBox="0 0 80 80">
            <g id="1-Mobile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Detalii-Artist" transform="translate(-295.000000, -58.000000)">
                <g id="Group" transform="translate(295.000000, 58.000000)">
                  <g id="Group-2" transform="translate(25.064971, 24.064971)" stroke="#FFFFFF" stroke-width="4">
                    <path d="M0.935028843,30.9350288 L31.8700577,0" id="Line-Copy-4"></path>
                    <path d="M0.935028843,30.9350288 L31.8700577,0" id="Line-Copy-4" transform="translate(16.000000, 15.500000) scale(-1, 1) translate(-16.000000, -15.500000) "></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </i>
      </header>

      <div v-show="isMainContentVisible"
           class="mtop20px">
        <img class="logo mbott30px"
             src="../assets/logo.svg" alt="multumult logo">
        <div class="clip-box">
          <AudioBox @action="handlePlayerState"
                    name="hero-song"
                    :start="200"
                    ref="audioPlayer"
                    img="outernational"
                    song-name="outernational" />
        </div>

        <h1>{{ template.title }}</h1>

        <button class="contact-btn" @click="scrollToTagClass('contact')">
          {{ template.contactUsBtn }}
        </button>

<!--        <section class="iframe-wrapper relative">-->
<!--          <iframe width="100%"-->
<!--                  name="article"-->
<!--                  height="800"-->
<!--                  src="https://taz.de/Neue-Musik-aus-Berlin/!5876508/" />-->
<!--        </section>-->

        <section class="announcements">
          <h2>{{ template.notifBox.title }}</h2>
          <p v-html="template.notifBox.text"></p>
          <figure>
            <a href="https://lollipoppeshoppe.bandcamp.com/album/now-and-then"
               target="_blank">
              <img src="../assets/albums/now-and-then.png"
                   alt="now and then MULTUMULT album">
            </a>
          </figure>
          <button class="listen-buy bold">
            <a href="https://lollipoppeshoppe.bandcamp.com/album/now-and-then"
               target="_blank">{{ template.notifBox.listenLink }}</a>
          </button>
          <article class="reviews-list">
            <h5 class="uppercase">{{ template.notifBox.reviews.title }}</h5>
            <p class="text-left">
              The term 'world music' has become increasingly vague over the years, and although this recording, from a trio of Romanian instrumental and literary artists, could be loosely described as such, the reference points are not Bregović, TPOK Jazz, or Yo Yo Ma. Instead, touchstones include The Necks, Evan Parker and Slowly Rolling Camera, with intimations of the Romanian Spectralist movement a more distant presence.
              <br>
              <br>
              The quartet – wind instruments, violin, electronics and percussion – work with folk-flavoured themes, taking a highly improvisatory approach, with structures emerging incrementally over the course of the piece. As an example, ‘Când Era în Vremea Mea’ opens with a series of drones, first as pulsing electronics rich in harmonics, joined by violin, with a strange insectile rattle of percussion, leading to a Phrygian-mode melody on clarinet, then a distant vocal, drenched in echo and reverb. It is not until halfway through the 13-minute piece that a drum pattern appears, underscoring a weave of slow instrumental meditations, enriched by electronic processing. At this point the texture has become so dense that it is impossible to be sure whether you are hearing electronics, a treated instrument, or an acoustic instrument being played unconventionally. A fascinating and thoughtful recording.
              <br>
              <br>
              Author: Kim Burton
              <a target="_blank"
                 href="https://www.songlines.co.uk/review/now-and-then">
                https://www.songlines.co.uk/review/now-and-then
              </a>
            </p>
            <br><br>
            <a href="http://ekultura.hu/2021/10/24/zenek-a-nagyvilagbol-multumult-now-and-then-vilagzenerol-szubjektiven-2982"
               target="_blank">
              Ekultura (HU)
            </a>
            <a href="https://www.musikansich.de/review.php?id=22497"
               target="_blank">
              Musikansich (DE)
            </a>
            <a href="/review-now-and-then-BE.pdf"
               target="_blank">
              Disgues (BE)
            </a>
          </article>
        </section>

        <section class="live-sessions relative" ref="live">
          <div class="scroll-indicator absolute">
            <div></div>
          </div>
          <h2><b>{{ template.liveSessions.title }}</b></h2>
          <div class="clips-wrapper">
            <div class="clip-box">
              <AudioBox @action="handlePlayerState"
                        name="live-session-balchik"
                        img="balchik"
                        song-name="balchik" />
              <p class="text-left">Balchik, live performance on the seaside - Without Borders 2019</p>
            </div>
            <div class="clip-box">
              <AudioBox @action="handlePlayerState"
                        name="live-session-peppi-g"
                        img="peppi-1"
                        song-name="peppi_1" />
              <p class="text-left">Peppi Guggenheim, Berlin</p>
            </div>
            <div class="clip-box">
              <AudioBox @action="handlePlayerState"
                        name="live-session-hermitage"
                        img="hermitage"
                        song-name="hermitage" />
              <p class="text-left">Hermitage Urban spiritual HUB, Bucuresti</p>
            </div>
          </div>
        </section>

        <section class="despre-noi" ref="despre">
          <h2>
            {{ template.about.title }}
          </h2>
          <h3>
            Multumult
          </h3>
          <p class="text-left"
             v-html="template.about.text" />
          <button class="contact-btn"
                  @click="scrollToTagClass('contact')">
            {{ template.contactUsBtn }}
          </button>
          <h2 class="uppercase">
            {{ template.about.core.title }}
          </h2>

          <div class="member" v-for="({ name, details, fullDetails, img }, index) in template.about.members"
               :key="index">
            <figure>
              <img :src="require(`../assets/members/${img}.png`)" alt="marina pingulescu">
            </figure>
            <h4>{{ name }}</h4>
            <p>{{ details }}</p>
            <button class="details-btn uppercase"
                    @click="showMemberDetails({img, fullDetails})">
              {{ template.about.details }}
            </button>
          </div>
        </section>

        <section class="albume" ref="albume">
          <h2>
            {{ template.albums.title }}
          </h2>
          <div v-for="({cover, details, link, reviews}, index) in template.albums.list"
               :class="index % 2 === 0 ? '' : 'hash-pattern'">
            <figure>
              <img :src="require(`../assets/albums/${cover}.png`)" alt="Deeper kind of yellow blue">
            </figure>
            <p v-html="details" />
            <a :href="link"
               target="_blank"
               class="details-btn uppercase">
              {{ template.albums.listen }}
            </a>
            <article v-show="reviews && reviews.length"
                     class="reviews-list">
              <h5>{{ template.notifBox.reviews.title }}</h5>
              <a v-for="({name, link}) in reviews"
                 :href="link"
                 target="_blank">
                {{ name }}
              </a>
            </article>
          </div>
        </section>

        <section class="povesti" ref="stories">
          <h2>
            {{ template.stories.title }}
          </h2>
          <h3>
            {{ template.stories.list[0].title }}
          </h3>
          <p class="text-left">
            {{ template.stories.list[0].text }}
          </p>
          <button class="details-btn uppercase hide"
                  @click="showStories()">
            {{ template.stories.more }}
          </button>
          <button class="contact-btn"
                  @click="scrollToTagClass('contact')">
            {{ template.contactUsBtn }}
          </button>
        </section>

        <section class="band-pictures">
          <figure>
            <img src="../assets/band-1.png" alt="all band">
            <img src="../assets/band-2.png" alt="all band">
            <img src="../assets/band-3.png" alt="all band">
            <img src="../assets/band-4.png" alt="all band">
            <img src="../assets/band-5.png" alt="all band">
          </figure>
        </section>

        <section id="contact"
                 class="contact"
                 ref="contact">
          <h2>
            {{ template.contact.title }}
          </h2>
          <p v-html="template.contact.text" />
          <ValidationObserver ref="observer"
                              class="base-form" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(handleSubmitMessage)"
                  class="text-center">
              <validation-provider rules="required"
                                   v-slot="{ errors }">
                <input type="text"
                       :class="{'disable-form': messageSent}"
                       :disabled="messageSent"
                       v-model="contactForm.name"
                       :placeholder="template.contact.form.name">
                <span class="validate-msg"
                      v-if="errors">{{ errors[0] }}</span>
              </validation-provider>
              <validation-provider rules="required"
                                   v-slot="{ errors }">
                <input type="text"
                       :disabled="messageSent"
                       v-model="contactForm.email"
                       :placeholder="template.contact.form.email">
                <span class="validate-msg"
                      v-if="errors">{{ errors[0] }}</span>
              </validation-provider>
              <validation-provider rules="required"
                                   v-slot="{ errors }">
                <textarea type="text"
                          :disabled="messageSent"
                          v-model="contactForm.message"
                          :placeholder="template.contact.form.msg" />
                <span class="validate-msg"
                      v-if="errors">{{ errors[0] }}</span>
              </validation-provider>
              <p v-if="messageSent">
                {{ template.messageSent }}
              </p>
              <button type="submit"
                      class="contact-btn uppercase">{{ template.contact.form.btn }}</button>
            </form>
          </ValidationObserver>
          <h2>
            {{ template.contact.follow.title }}
          </h2>
          <p v-html="template.contact.follow.text" />
          <figure class="flex flex-between">
            <a href="https://www.facebook.com/multumult/" target="_blank">
              <img src="../assets/fb.svg" alt="facebook link">
            </a>
            <a href="https://www.youtube.com/channel/UCqmnDEj_aoaSU_AAt1q53Vw/videos" target="_blank">
              <img src="../assets/youtube.svg" alt="youtube link">
            </a>
          </figure>
          <button class="uppercase"
                  @click="scrollToTagClass('home-page', -50)">
            <img src="../assets/triangle.svg" alt="back to top">
            <br>
            {{ template.upBtn }}
          </button>
        </section>
      </div>

    </main>
  </div>
</template>

<script>
  import MemberDetails from "@/components/MemberDetails"
  import Stories from "@/components/Stories"
  import Meniu from "@/components/Meniu"
  import AudioBox from "@/components/AudioBox"
  import { EventBus } from '@/main'
  import { mapGetters, mapState } from 'vuex'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  export default {
    name: 'Home',
    components: { MemberDetails, Stories, Meniu, AudioBox, ValidationObserver, ValidationProvider },
    data () {
      return {
        stories: [
          {
            titlu: 'abuabua',
            text: 'Formarea grupului multumult a fost anticipată de prima colaborare Gherghel–Torsan, care i-a surprins pe cei doi în roluri diferite: Torsan, ca muzician de scenă; Gherghel, ca producător și inginer de sunet al grupului abuabua. Așa a fost înregistrat și editat albumul O mână de boabe (2015), inspirat din cântecele de leagăn ale diferitelor culturi tradiționale.'
          },
          {
            titlu: 'TLM label',
            text: 'Întâlnirea cu Moody Al și label-ul său, Thirsty Leaves Music, a dus la apariția primelor noastre două albume: ZIGZAG (2015) și Deeper Kind of Yellow Blue (2018). Ne amintim cu mare plăcere și de cele două spectacole din Salonic, un concert și ilustrarea live a filmului mut Independența României, susținute cu ocazia aniversării a trei ani de activitate editorială a TLM.'
          },
          {
            titlu: 'Istanbul',
            text: 'O aventură de-o singură noapte, cea cu concertul din Gitar Cafe, trăită după un drum dificil și obositor, dar împlinită de frumusețea aparte a Istanbulului. Ne-am făcut atunci mai mulți prieteni cu care am băut cafele și rachiuri, punând țara la cale ca pe vremea domnitorilor fanarioți. Târziu, după spectacol, le-am cântat gazdelor noastre la caval și la tăblia mesei. Pe geam, se vedeau dansând licăririle Bosforului.'
          },
          {
            titlu: 'Germania/ Berlin',
            text: 'Experiența noastră din Germania seamănă cu un ștrudel. Unul cu multă umplutură de mere, de brânză și nuci. Ar trebui feliat, ca să înțelegeți mai multe. Și să puteți gusta una dintre cele mai frumoase și intense încercări ale grupului. Vom începe, așadar, cu Berlinul, ca să dăm Cezarului fix ce este al lui. Iar aici ar fi de enumerat legătura cu Henning Küpper și label-ul său, The Lollipop Shop, cu care vom edita curând al treilea nostru disc. Și concertele din: Peppi Guggenheim, un club din Neukölln; Brotfabrik, acolo unde am ilustrat live câteva filme mute; sau Klang-Holz, locul în care am cântat printre lutieri și instrumentele lor.'
          },
          {
            titlu: 'Germania/ Leipzig',
            text: 'În Leipzig am ajuns de mai multe ori. Prima oară, cu ocazia marelui Târg de Carte de-acolo. Am improvizat un show scurt, interactiv, pentru lansarea antologiei Einladung nach Rumänien (realizată de Elsa Lüder, pentru editura berlineză Noack & Block). Deși România era invitata de onoare a acelei ediții, cartea nu și-a găsit locul pe rafturile și printre lansările standului național. Așa că ne-am desfășurat la câțiva pași distanță, în entuziasmul celor prezenți și sub privirile piezișe ale câtorva compatrioți. Găzduiți într-un spațiu neutru. Întâlnirea cu Georg și al său Noch Besser Leben, o locație pentru evenimente muzicale live, a făcut uitată amărăciunea mioritică. Și chiar am cântat acolo, cu mare bucurie, poemul nostru, Mioritzza. Repetând experiența anul următor, dar în alt club local. În Subbotnik.'
          },
          {
            titlu: 'Germania/ Chemnitz',
            text: 'În fostul Karl-Marx-Stadt, acolo unde tronează încă bustul imens al filozofului, am făcut cel mai mare frig din Germania. Am plecat din Leipzig, cu o ocazie. Iar tinerii ăia, care ne-au luat în mașină, ne-au lăsat la marginea unui câmp înghețat. Era jumătatea lui martie, dar frigul stăpânea absolut. Ne-am orientat dificil și ne-am găsit refugiu într-o brutărie cu produse fierbinți, unde am băut ceai după ceai. Ceva mai încolo, în Odradek Cafe, ne-am săturat cu resturile reci (gogoși și prăjituri pudrate cu zahăr) ale unei petreceri desfășurate cu trei zile în urmă. Apoi, seara, am adunat lume buluc, inclusiv câțiva români cu care am încins la final un soi de horă electronică.'
          },
          {
            titlu: 'Germania/ Ulm',
            text: 'Am ajuns aici prin intermediul ICR și am cântat în Donauschwabisches Zentralmuseum. Cum se putea mai bine, pentru niște dunăreni? Orășelul vechi pare alcătuit din case de turtă dulce, iar turnul bisericii gotice, cel mai înalt din lume, străjuiește întreaga regiune Tübingen. Întâmplător, ne găseam în plină desfășurare a Campionatului Mondial de fotbal din Rusia, unde Germania a fost eliminată surprinzător, după înfrângerile cu Mexic (0-1) și Coreea de Sud (0-2). Al doilea meci l-am văzut în plină stradă, holbându-ne cu berile în față la un ecran gigantic și la fețele deziluzionate ale suporterilor germani. Sprea seară a fost programat și concertul nostru. În mijlocul celor prezenți, s-a aflat un fan îmbrăcat cu tricoul și în șortul Nationalelf. Aproape beat de supărare.'
          },
          {
            titlu: 'Germania/ Weinböhla',
            text: 'La Hannes, prietenul care locuiește în fosta clădire a primăriei locale, am ajuns vara. Scoteai limba de cald ce era... Ne-am supus formatului de Gartenmusik Fest – manifestarea organizată de el în propria grădină, pe parcursul unei după-amieze și seri –, cântând complet acustic. N-a fost ușor, fiindcă n-o mai făcusem de mult, obișnuiți să ne tot procesăm instrumentele. Dar a fost bine. După concert, am devenit spectatorii relaxați ai showului african de kora și percuții. Apoi am ascultat un povestitor german, expresiv și convingător. Timp de alte două zile, ne-am încuiat în camerele cu colecția de instrumente exotice ale lui Hannes. Casa lui este un adevărat muzeu local inedit. Cântam la te miri ce, uitând chiar să mai și mâncăm.'
          },
          {
            titlu: 'Cu Tenda/ prin București',
            text: 'Includerea noastră în proiectul european Cu Tenda, dedicat aromânilor, ne-a oferit loc de joacă într-o lume nouă. Și ne-a făcut cunoscute câteva colțuri de tsarâ. Caravana a pornit din București, cu un micro-concert dat la vernisajul expoziției omonime. Am trecut proba de foc a nativilor, sugerând o mișcare nomadă prin sala de expunere și prelucrând teme culese din repertoriul lor. A fost ca un botez neașteptat, ce-ar fi putut schimba numele nostru – respectând idiomurile specifice – în multu multu (trad.: foarte mult).'
          },
          {
            titlu: 'Cu Tenda/ prin Plovdiv',
            text: 'Întâlnirea cu cetatea din Plovdiv, de pe stradelele căreia am cules o parte dintre spectatorii vernisajului și concertului nostru, a fost surprinzătoare. În modul foarte plăcut. Nici nu știu cu ce să încep: cu vinul alb și foarte parfumat, băut într-o seară de pomină, la care ne gândim și acum; cu cerșetorul muzicant ce manevra simultan un fluier și-o tobă, instrumente emblematice din arsenalul grupului nostru; cu gazda unde-am locuit, o aromâncă stabilită acolo încă din vremea bunicilor săi; sau cu acele spectacole pe care le-am putut vedea seara, în amfiteatrul roman, cu ocazia unui festival internațional de folclor desfășurat chiar atunci. '
          },
          {
            titlu: 'Cu Tenda/ prin Kruševo',
            text: 'În orășelul din Macedonia de Nord, am fost urmăriți permanent de două personaje: umbra cântărețului Toše Proeski, simbolul național dispărut prematur și supranumit Elvis din Balcani; și Pepsi, un tip ce învârtea mai de toate (afaceri cu zahăr, schimb valutar etc.), lipit de grupul nostru și făcându-ne diminețile mai frumoase. Am cântat într-o casă de cultură pipernicită, un pumn de cărămidă rispită printre munții pleșuvi ce ne înconjurau (localitatea este una dintre cele mai înalte din spațiul balcanic, situată la 1350 de metri deasupra mării. Predealul, echivalentul românesc, se mulțumește cu numai 1110 metri).'
          },
          {
            titlu: 'Cu Tenda/ prin Skopje',
            text: 'În capitala Macedoniei de Nord, am tras la un hotel albanez din Vechiul Bazar. Am fost întâmpinați la recepție, în momentul cazării, cu cafea și zaharicale. Peste tot, în jurul nostru, o lume altfel, cochetând cu trecutul și punând accentul pe alte detalii. Din turlele lor, imamii despicau ziua în cinci felii fierbinți. Printre magazinele cu bijuterii din aur și argint, mai puteai găsi câte-o plăcintărie pe cinste. Am cântat într-un centru multimedia, o căzătură cu pretenții nejustificate, unde singura expoziție (dedicată minorității albaneze) le reproducea în fotografii prinse cu bolduri pe Dua Lipa, Rita Ora și Bebe Rexha.  '
          },
          {
            titlu: 'Cu Tenda/ prin Tricase',
            text: 'În tocul Cizmei am ajuns în preajma Crăciunului, lăsând frigul de-acasă și dând de-o vreme dulce, tomnatică. Un orășel mic, ridicat din piatră, de tipul celor ce populează cinematografia clasică italiană. Am reușit performanța să încurcăm blocul unde-am locuit, fiindcă în preajmă erau construite unele identice. După 24 de ani, mi-am revăzut acolo niște prieteni vechi din Lecce. Iar într-o seară, am cumpărat însetați vin la borcan. Ca pe malul Dâmboviței! În sfârșit, la întoarcere, din cauza unor probleme de bagaj, am pornit-o spre avion sărind peste cordonul de control. Polițista de frontieră ne-a preparat cu ou și cu oțet, iar pe mine m-a făcut prost. Concertul l-am dat într-o galerie lunguiață de artă. Mai toată lumea era preocupată de feliile calde de cozonac și de paharele cu prosecco. La final, ca greierele din fabulă, noi ne-am lins pe bot. S-au săturat, în schimb, furnicile. Formiche, cum spun italienii. '
          },
          {
            titlu: 'Balcic',
            text: 'În fosta reședință de vară a reginei Maria, am ajuns la un final dulce de septembrie. Marea și cerul se amestecaseră în aceeași nuanță, lăsând liber zborul gândurilor omenești și-al pescărușilor sau albatroșilor. Henning ne-a invitat acolo, promotorul nostru din Berlin. Ca participanți la un simpozion pe teme de World Music. Lucruri foarte interesante am ascultat acolo, cunoscând – în plus – tot felul de muzicieni și oameni din domeniu. Concertul susținut pe malul mării ne-a adus aplauze și laude îmbucurătoare, ba chiar și promisiunea (concretizată deja) de-a colabora cu unii dintre cei întâlniți acolo.'
          },
          {
            titlu: 'În București/ la Muzeul Național de Artă',
            text: 'Eram băgați pe-atunci, până în trompele lui Eustachio, în meandrele muzicilor levantine. Așa că ne-am... băgat în seamă, aflând de expoziția Oglinzile Orientului. Am așternut covor lung, am filmat, am înregistrat și-am adunat o mulțime de oameni. Ni s-a promis că altădată vom fi răsplătiți, dar asta s-a uitat, căci sacii din căruță nu mai cer eforturi. O dimineață foarte frumoasă, urmată de-un dejun exotic la mine acasă: cu humus, tabbouleh și alte alea.'
          },
          {
            titlu: 'București/ în Clubul Țăranului',
            text: 'Multe lucruri frumoase am făcut aici... Iar asta s-a datorat oamenilor care sfințeau locul. Și care ne-au sprijinit mereu, ba să ilustrăm un film mut, ba să ne văicărim în timpul concertului Funebru, ba să oferim mărțișoare muzicale ascultătoarelor noastre. Cu ocazia unei cântări, Dorin, prietenul meu homles, a avut masă rezervată, friptură cu cartofi prăjiți în meniu și-o carafă cu vin lângă farfurie. Detalii pentru care-i suntem recunoscători lui Adi. Măcar aici să batem darabana despre gesturile astea!'
          },
          {
            titlu: 'București/ în Green Hours',
            text: 'În cunoscutul club de jazz, am făcut destule: ne-am lansat albumul Deeper Kind of Yellow Blue, am cântat în perioada când ne turcisem complet și-am dat minunatul concert matinal, ciuciți pe marginea farfurioarei pentru ceașca de cafea. '
          },
          {
            titlu: 'București/ în Trei Bețivi',
            text: 'Cea mai tare întâlnire din capitală a fost cu Vali și Victor, celebrul Volkswagen din Trei Bețivi. Băieții ăștia știu muzică, au ascultat ciudățenii și nu s-au ferit să propună experimente în spațiul de care se ocupă. Prin voia lor, micuțul pub de pe Vasile Lascăr a devenit, de multe ori, adevăratul pol al avangardei românești în sunet. Poate-ar fi mai nimerit titlul: Berlin/ in Drei Betrunkene. Hai, noroc!'
          },
          {
            titlu: 'Prin țară',
            text: 'Multe-au fost drumurile noastre în țară! În urma unui vis, am poposit în Alba-Iulia, în plin târg de carte. Am făcut circ pe-acolo, ieșind cu microfoanele portabile pe străzile din apropierea Casei de Cultură. În Sibiu am mai ajuns, chiar în sala de festivități a Bibliotecii Județene. Mai apoi, ca invitați ai festivalului TIFF, pentru ilustrarea unui film mut proiectat la Teatrul Gong. În Cluj, de mai multe ori, în contexte complet diferite. Ne-am purtat bagajele și în Moldova: la biblioteca din Roman, unde-am tânguit Mioritzza noastră; sau în Câmpulung Moldovenesc, găzduiți de zilele Outernational. Am răscolit pietrișul din grădina unui pub ploieștean, acolo unde patronul ne cerea insistent să cântăm și „d-alea, mai mișcate”. În Brăila, am găsit-o și-am improvizat în casa memorială „Panait Istrati”, scriitorul fără... casă și veșnic drumeț. Iar în Craiova, ne-am oprit în mijlocul librăriei „Elena Farago”, printre prieteni mari și mici. Pe toate traseele astea, munții ne-au zâmbit frumos, dealurile ne-au făcut cu mâna, râurile ne-au arătat drumul drept, iar soarele și oamenii ne-au înconjurat cu căldură. Ce-ți poți dori mai mult? Poate doar câteva d-alea, mai mișcate...'
          }
        ],
        isMemberDetails: false,
        isAllStories: false,
        isMenu: false,
        isMenuIcon: true,
        memberName: undefined,
        memberDetails: undefined,
        scrollTop: 0,
        isMainContentVisible: true
      }
    },
    created () {
      this.$store.dispatch('get_utube_clips');
    },
    computed: {
      ...mapState({
        contactForm: 'contactForm',
        messageSent: 'messageSent'
      }),
      ...mapGetters(['template', 'meniu', 'otherLanguages']),
      clips () {
        return this.$store.state.youtube.clips
      }
    },
    methods: {
      changeLanguage (lang) {
        this.$store.commit('CHANGE_LANGUAGE', lang)
      },
      handleSubmitMessage () {
        this.$store.dispatch('send_msg', this.$refs.observer)
      },
      handlePlayerState ({name}) {
        EventBus.$emit('stop-all', {name})
      },
      scrollToTagClass (id, offset) {
        this.$smoothScroll({
          offset: offset || 80,
          duration: 2500,
          scrollTo: document.getElementById(id),
          hash: '#sampleHash'  // required if updateHistory is true
        })
      },
      showMemberDetails ({img, fullDetails}) {
        this.memberName = img;
        this.memberDetails = fullDetails;
        this.isMemberDetails = true;
        this.scrollTop = window.scrollY;
        this.isMenuIcon = false;
      },
      hideDetails () {
        this.isMemberDetails = false;
        this.isAllStories = false;
        this.isMainContentVisible = true;
        this.isMenu = false;
        this.scrollToLastPosition();
        this.isMenuIcon = true;
      },
      showStories () {
        // this.isAllStories = true;
        // this.scrollTop = window.scrollY;
      },
      showMenu () {
        this.isMenu = true;
        this.scrollTop = window.scrollY;
        setTimeout(() => {
          this.isMainContentVisible = false;
        }, 200);
      },
      goToSection (section) {
        this.isMenu = false;
        this.isMainContentVisible = true;
        this.scrollToLastPosition();
        setTimeout(() => {
          this.$smoothScroll({
            offset: 40,
            duration: 1000,
            scrollTo: this.$refs[section],
            hash: '#sampleHash'  // required if updateHistory is true
          })
        }, 120);
      },
      scrollToLastPosition () {
        this.$nextTick(() => {
          window.scrollTo(0, this.scrollTop);
        });
      }
    }
  }
</script>

<style lang="scss">
  @import "../styles/var";
  .home-page {
    @media only screen and (min-width: 0) {
      padding: 16px 0 0;
      .iframe-wrapper {
        background-color: white;
        iframe {
          margin-left: 60px;
          border: none;
        }
        &::before {
          content: "";
          height: 97px;
          width: 100%;
          left: 0;
          top: 0;
          display: block;
          position: absolute;
          background-color: black;
          z-index: 9;
        }
      }
      nav.meniu-box {
        width: 100%;
        height: 100%;
        background-color: black;
        z-index: 90;
        li {
          font-size: 3.4rem;
          font-family: Barlow, sans-serif;
          color: white;
          padding: 13px 0;
        }
      }
      img.settings-icon {
        transition: transform 150ms ease-in-out;
        right: 0;
      }
      .hide-settings-icon {
        transform: translateX(60px);
      }
      i.close-menu-icon {
        right: -77px;
        top: -19px;
        transition: transform 150ms ease-in-out;
      }
      .slide-close-icon {
        transform: translateX(-60px);
      }
      header {
        overflow: hidden;
        &.fixed {
          width: 38px;
          border-radius: 4px;
          right: 20px;
          top: 36px;
        }
        i {
          position: absolute;
        }
      }
      img.logo {
        width: 80px;
      }
      figure.hero-img {
        max-width: 336px;
        margin: 38px auto 27px;
        img {
          width: 100%;
        }
      }
      div.scroll-indicator {
        display: none;
      }
      h1 {
        padding: 0 8px;
        color: white;
        font-size: 3.4rem;
        font-family: Barlow, sans-serif;
      }
      h4 {
        font-family: Roboto, sans-serif;
        font-size: 2.8rem;
        color: white;
      }
      .contact-btn {
        padding: 16px 32px;
        margin: 40px auto 100px;
        border: 4px solid $purple;
        background: black;
        color: white;
        font-family: Roboto, sans-serif;
        font-weight: 900;
        font-size: 1.5rem;
        letter-spacing: 2.14px;
        display: block;
      }

      section {
        h2 {
          color: $gray;
          font-size: 18px;
          font-family: Roboto, sans-serif;
          letter-spacing: 2.57px;
          line-height: 2.9rem;
          text-transform: uppercase;
        }
        h3 {
          font-family: Barlow, sans-serif;
          font-size: 3.4rem;
          line-height: 4.2rem;
        }
        .details-btn {
          color: $purple;
          font-size: 1.8rem;
          letter-spacing: 2.14px;
          background: transparent;
          border: none;
          font-family: Roboto, sans-serif;
          font-weight: 900;
        }
        p {
          margin: 20px 16px;
          font-size: 1.8rem;
          line-height: 3rem;
        }
      }

      .announcements {
        padding: 40px;
        background-color: white;
        border-bottom: 1px solid lightgray;
        background-image: url("../assets/bg-paperboard-yellow-texture.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        h2 {
          padding: 8px 14px;
          display: inline-block;
          background-color: #b48546;
          background-image: url("../assets/bg-new-album-title.png");
          background-size: cover;
          color: white;
          font-size: 2.2rem;
        }
        p {
          max-width: 600px;
          margin: 16px auto 0;
          color: #362116;
        }
        button.listen-buy {
          margin: 24px auto;
          padding: 8px 16px;
          display: block;
          font-size: 2rem;
          border: 1px solid gray;
          transition: padding 100ms ease-in-out;
          position: relative;
          &:hover {
            padding: 8px 26px;
            &:before {
              height: 26px;
            }
          }
        }
        figure {
          width: 100%;
          max-width: 400px;
          margin: 32px auto 0;
          transition: transform 100ms ease-in-out;
          transform-origin: bottom;
          img {
            width: 100%;
          }
          &:hover {
            transform: scale(1.05);
            transform-origin: bottom;
          }
        }
        figure + a {
          margin-top: 12px;
          display: inline-block;
          font-size: 1.8rem;
          text-decoration: underline;
        }
      }

      article.reviews-list {
        margin-top: 16px;
        padding: 0 24px 24px;
        display: block;
        h5 {
          font-size: 1.8rem!important;
          padding: 12px 0;
        }
        a {
          display: block;
          font-size: 1.8rem;
          margin-bottom: 8px;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .live-sessions {
        padding: 92px 0 20px;
        background-color: white;
        p {
          font-size: 1.4rem;
          margin: 12px 0;
          line-height: 2.4rem;
          color: #7F7F7F;
          font-weight: 600;
        }
      }

      .clips-wrapper {
        margin: 52px auto 0;
        max-width: 335px;
        h3 {
          font-size: 14px;
          color: $gray;
          font-family: Roboto, sans-serif;
          font-weight: 700;
          margin-top: 12px;
        }
      }

      .clip-box {
        width: 300px;
        margin: 0 auto 38px;
        .clip {
          width: 100%;
          cursor: pointer;
          position: relative;
          &:before {
            content: "";
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            z-index: 1;
          }
          .play-button {
            &:before {
              content: "";
              border-style: solid;
              border-width: 15px 0 15px 26.0px;
              border-color: transparent transparent transparent #fff;
              top: 50%;
              left: 50%;
              transform: translate3d( -50%, -50%, 0 );
              position: absolute;
              z-index: 2;
            }
          }
          img {
            width: 100%;
          }
        }
      }

      .despre-noi {
        padding-top: 92px;
        h3 {
          margin-top: 24px;
          font-family: Barlow, sans-serif;
          color: white;
          font-size: 3.4rem;
        }
        p {
          color: lightgray;
          font-size: 1.8rem;
          font-family: Roboto, sans-serif;
        }
        .member {
          margin: 76px 0 90px;
          figure {
            margin-bottom: 36px;
            img {
              width: 140px;
            }
          }
        }
      }

      section.albume {
        padding: 89px 0 0;
        background: white;
        h2 {
          margin-bottom: 53px;
        }
        p {
          margin: 30px 0 20px;
          font-size: 1.4rem;
          color: gray;
          line-height: 2.4rem;
          b {
            margin-bottom: 16px;
            display: inline-block;
            font-family: Barlow, sans-serif;
            font-size: 3.4rem;
            color: black;
            line-height: 4.5rem;
          }
        }
        img {
          width: 275px;
        }
        div.hash-pattern {
          margin-top: 40px;
          padding: 50px 0;
          background-image: url("data:image/svg+xml,%0A%3Csvg width='6px' height='6px' viewBox='0 0 6 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='1-Mobile' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Crect id='Rectangle' fill='%23EBEBEB' x='0' y='0' width='3' height='3'%3E%3C/rect%3E%3Crect id='Rectangle-Copy-5' fill='%23FFFFFF' x='0' y='3' width='3' height='3'%3E%3C/rect%3E%3Crect id='Rectangle-Copy-2' fill='%23FFFFFF' x='3' y='0' width='3' height='3'%3E%3C/rect%3E%3Crect id='Rectangle-Copy-6' fill='%23EBEBEB' x='3' y='3' width='3' height='3'%3E%3C/rect%3E%3C/g%3E%3C/svg%3E");
          figure {
            margin-bottom: 27px;
          }
          & + div {
            padding: 50px 0 40px;
          }
        }
      }

      section.povesti {
        padding-top: 92px;
        h3 {
          padding: 24px 16px 8px;
          color: white;
        }
        p {
          margin-bottom: 42px;
          color: lightgray;
        }
        button {
          margin-top: 77px ;
        }
      }

      section.band-pictures {
        img {
          width: 100%;
        }
      }

      section.contact {
        padding: 91px 0 24px;
        background: white;
        h2 {
          font-family: Barlow, sans-serif;
          font-size: 3.4rem;
          color: black;
          letter-spacing: 0;
        }
        form {
          max-width: 600px;
          margin: 0 auto;
          padding: 0 16px;
          overflow-y: hidden;
        }
        input.disable-form {
          margin-top: -340px;
        }
        input,
        textarea {
          width: 100%;
          transition: margin-top 300ms ease-in-out;
          display: block;
          text-align: center;
          font-family: Roboto, sans-serif;
          font-weight: 500;
          border: 4px solid #DADADA;
          font-size: 2rem;
          -webkit-appearance: none!important;
          -moz-appearance: none;
          appearance: none;
          box-shadow: none !important;
          -webkit-border-radius: 0;
        }
        span.validate-msg {
          margin: 6px auto 20px;
          display: block;
          font-size: 1.2rem;
          color: red;
        }
        input {
          padding: 10px;
        }
        textarea {
          padding-top: 12px;
          resize: none;
          height: 186px;
        }
        button {
          background: transparent;
        }
        button.contact-btn {
          width: 204px;
          color: black;
        }
        h2 + p {
          color: gray;
        }
        figure {
          width: 100px;
          margin: 54px auto 0;
          a:last-child img {
            width: 50px;
          }
        }
        figure + button {
          margin: 100px 0 60px;
          border: none;
          font-size: 1.8rem;
          color: $purple;
          font-family: Roboto, sans-serif;
          font-weight: 600;
        }
      }
    }

    @media only screen and (min-width: 375px) {
      div.scroll-indicator {
        top: -45px;
        left: calc(50% - 2px);
        display: block;
        height: 90px;
        overflow: hidden;
        div {
          height: 90px;
          width: 4px;
          transform: translateY(-90px);
          background-color: #A667AA;
          animation: scroll-indicator 2.5s infinite ease-in-out;
        }
      }
      .clip-box {
        width: 336px;
      }
    }

    @media only screen and (min-width: 768px) {
      div.scroll-indicator {
        display: none;
      }
      .despre-noi,
      .povesti {
        width: 460px;
        margin: 0 auto;
      }
    }

    @media only screen and (min-width: 1024px) {
    	.announcements {
        button.listen-buy:before {
          content: "";
          display: block;
          position: absolute;
          height: 10px;
          border-left: 2px solid gray;
          bottom: 40px;
          left: 50%;
          transition: height 100ms ease-in-out;
        }
      }
    }

    @keyframes scroll-indicator {
      0% {
        transform: translateY(-70px);
        opacity: 0;
      }
      15% {
        opacity: 1;
      }
      25% {
        transform: translateY(0);
      }
      27% {
        transform: translateY(0);
      }
      42% {
        opacity: 1;
      }
      60% {
        opacity: 1;
        transform: translateY(90px);
      }
      61% {
        opacity: 0;
      }
      100% {
        opacity: 0;
        transform: translateY(-90px);
      }
    }
  }
</style>
