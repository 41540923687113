<template>
  <main class="oriental-mirrors">
    <h1>Oriental Mirrors</h1>

    <figure class="text-center">
      <img id="imageToDownload"
           src="../assets/albums/oriental-mirrors.jpeg" alt="Multumult - Oriental Mirrors" />
      <br>
      <button @click="downloadCover">download cover</button>
    </figure>

    <ul class="flex flex-between">
      <li class="relative"
          v-for="(item, index) in 12"
          :key="index">
        <span class="absolute">
          {{ item }}.
        </span>
        <SongCard
            :songUrl="`https://multumult.ro/music/oriental-mirrors/${item < 10 ? '0' + item : item}.mp3`"
        />
<!--        <h2>{{ item }}</h2>-->
<!--        <video controls="">-->
<!--          <source :src="`https://multumult.ro/music/oriental-mirrors/${item < 10 ? '0' + item : item}.mp3`" type="audio/mpeg">-->
<!--        </video>-->
      </li>
    </ul>

    <p>
      Multumult – Oriental Mirrors/Oglinzi Orientale
      <br>
      The Lollipoppe Shoppe (<a href="http://lollipopshop.de/" target="_blank">lollipopshop.de</a>)
      <br>
      Cat.-Nr.. LSCD 028, Format: CD/ DL
      <br>
      EAN: 2090505474770
      <br>
      release date: June 07, 2024
      <br><br>
    </p>

    <nav>
      <a href="https://lollipoppeshoppe.bandcamp.com/album/oriental-mirrors-oglinzi-orientale"
         target="_blank">
        https://lollipoppeshoppe.bandcamp.com/album/oriental-mirrors-oglinzi-orientale
      </a>

      <a href="./Reviews-Multumult-Now-and-Then.pdf"
         download="Reviews-Multumult-Now-and-Then.pdf">
        Reviews multumult now and then
      </a>

      <a href="./Multumult-Info-lscd-028-EN.pdf"
         download="Multumult-Info-lscd-028-EN.pdf">
        Multumult Info lscd 028 EN
      </a>
    </nav>
  </main>
</template>

<script>
  import SongCard from "@/components/SongCard.vue";

  export default {
    name: 'OrientalMirrors',
    components: {SongCard},
    methods: {
      downloadCover () {
        const image = document.getElementById('imageToDownload');
        const imageUrl = image.src;
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = 'multumult-oriental-mirrors.jpg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
</script>

<style lang="scss">
  .oriental-mirrors {
    @media only screen and (min-width: 0) {
      padding: 48px 48px 120px;
      color: white;
      h1 {
        text-align: center;
        font-size: 4rem;
        color: white;
      }
      h2 {
        position: absolute;
        font-size: 2rem;
        color: white;
        bottom: 70px;
        left: 20px;
      }
      figure {
        margin-top: 24px;
        img {
          max-width: 400px;
        }
        button {
          margin-top: 16px;
          padding: 6px 16px;
          background: transparent;
          border: none;
          border-radius: 4px;
          font-size: 1.8rem;
          color: white;
          font-weight: bold;
        }
      }
      ul {
        max-width: 900px;
        margin: 48px auto;
        flex-wrap: wrap;
        li {
          > span {
            font-size: 2.4rem;
            left: 20px;
            top: 20px;
            color: white;
            z-index: 99;
          }
        }
      }
      nav, p {
        font-size: 2.4rem;
        text-align: center;
        a {
          color: white;
          text-decoration: underline;
        }
      }
      nav a {
        display: block;
        margin-bottom: 16px;
      }
    }
  }
</style>