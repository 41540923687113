<template>
  <div class="song-card"
       :class="{'is-playing': isPlaying}">
    <button @click="togglePlay">
      <i v-if="isPlaying">
        <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M28.5 55C43.136 55 55 43.136 55 28.5C55 13.8641 43.136 2 28.5 2C13.8641 2 2 13.8641 2 28.5C2 43.136 13.8641 55 28.5 55Z" stroke="white" stroke-width="4" stroke-linejoin="round"/>
          <path d="M21.875 20.55V36.45M35.125 20.55V36.45" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </i>
      <i v-else>
        <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.0357 39.75C16.5337 39.75 16.0522 39.5505 15.6972 39.1956C15.3422 38.8406 15.1428 38.3591 15.1428 37.8571V15.1428C15.143 14.8203 15.2256 14.5032 15.3827 14.2215C15.5399 13.9399 15.7664 13.703 16.0408 13.5335C16.3152 13.364 16.6283 13.2674 16.9505 13.2529C17.2727 13.2384 17.5933 13.3064 17.8818 13.4506L40.5961 24.8077C40.9101 24.9651 41.1741 25.2067 41.3586 25.5056C41.5431 25.8045 41.6408 26.1487 41.6408 26.5C41.6408 26.8512 41.5431 27.1955 41.3586 27.4943C41.1741 27.7932 40.9101 28.0348 40.5961 28.1922L17.8818 39.5493C17.6191 39.681 17.3295 39.7496 17.0357 39.75ZM18.9285 18.2055V34.7945L35.5175 26.5L18.9285 18.2055Z" fill="white"/>
          <path d="M26.5 3.78571C30.9925 3.78571 35.384 5.11788 39.1194 7.61376C42.8547 10.1096 45.7661 13.6571 47.4853 17.8076C49.2045 21.9581 49.6543 26.5252 48.7778 30.9313C47.9014 35.3375 45.7381 39.3848 42.5614 42.5614C39.3848 45.7381 35.3375 47.9014 30.9313 48.7778C26.5252 49.6543 21.9581 49.2044 17.8076 47.4853C13.6571 45.7661 10.1096 42.8547 7.61377 39.1194C5.11789 35.384 3.78573 30.9925 3.78573 26.5C3.78573 20.4758 6.17883 14.6983 10.4386 10.4386C14.6983 6.17882 20.4758 3.78571 26.5 3.78571ZM26.5 0C21.2588 0 16.1353 1.5542 11.7774 4.46605C7.4195 7.37791 4.02293 11.5166 2.0172 16.3589C0.011482 21.2011 -0.513306 26.5294 0.509202 31.6699C1.53171 36.8104 4.05559 41.5322 7.76168 45.2383C11.4678 48.9444 16.1896 51.4683 21.3301 52.4908C26.4706 53.5133 31.7989 52.9885 36.6411 50.9828C41.4834 48.9771 45.6221 45.5805 48.5339 41.2226C51.4458 36.8647 53 31.7412 53 26.5C53 19.4718 50.208 12.7314 45.2383 7.76167C40.2686 2.79196 33.5282 0 26.5 0Z" fill="white"/>
        </svg>
      </i>
    </button>
    <img src="../assets/albums/oriental-mirrors.jpeg"
         alt="Album Art"
         class="album-art" />
    <audio :src="songUrl" ref="audio" />
  </div>
</template>

<script>
  export default {
    props: {
      songUrl: String,
      item: String
    },
    data() {
      return {
        isPlaying: false
      };
    },
    methods: {
      togglePlay() {
        const audio = this.$refs.audio;
        if (audio.paused) {
          audio.play();
          this.isPlaying = true;
        } else {
          audio.pause();
          this.isPlaying = false;
        }
      }
    }
  };
</script>

<style scoped>
  .song-card {
    position: relative;
    width: 200px;
    height: 200px;
    margin: 10px;
    border: 1px solid white;
    &:hover {
      img,
      button i {
        opacity: 1;
      }
    }
    &.is-playing {
      img,
      button i {
        opacity: 1;
      }
    }
  }
  .album-art {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.4;
  }
  button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }
  button i {
    pointer-events: none;
    opacity: 0.6;
  }
</style>
