<template>
	<div class="audio-box flex-center"
       :ref="name">
    <!-- PAUSE -->
    <i v-if="isPlaying"
       class="pointer"
       @click="pause()">
      <svg width="62px" height="62px" viewBox="0 0 62 62">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path d="M40.4347826,43.1304348 L40.4347826,18.8695652 L33.6956522,18.8695652 L33.6956522,43.1304348 L40.4347826,43.1304348 Z M28.3043478,43.1304348 L28.3043478,18.8695652 L21.5652174,18.8695652 L21.5652174,43.1304348 L28.3043478,43.1304348 Z M31,0 C36.6634899,0 41.9046233,1.41585123 46.7235577,4.24759615 C51.3934528,6.93030187 55.0696981,10.6065472 57.7524038,15.2764423 C60.5841488,20.0953767 62,25.3365101 62,31 C62,36.6634899 60.5841488,41.9046233 57.7524038,46.7235577 C55.0696981,51.3934528 51.3934528,55.0696981 46.7235577,57.7524038 C41.9046233,60.5841488 36.6634899,62 31,62 C25.3365101,62 20.0953767,60.5841488 15.2764423,57.7524038 C10.6065472,55.0200184 6.93030188,51.3189336 4.24759615,46.6490385 C1.41585123,41.8301041 0,36.6138101 0,31 C0,25.3861899 1.41585123,20.1698959 4.24759615,15.3509615 C6.97998161,10.6810664 10.6810664,6.97998161 15.3509615,4.24759615 C20.1698959,1.41585123 25.3861899,0 31,0 Z" fill="#ffffff" fill-rule="nonzero"></path>
        </g>
      </svg>
    </i>
    <!-- PLAY -->
    <i v-else
       class="pointer"
       @click="play()">
      <svg width="62px" height="62px" viewBox="0 0 62 62">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path d="M31,0 C13.8854167,0 0,13.8854167 0,31 C0,48.1145833 13.8854167,62 31,62 C48.1145833,62 62,48.1145833 62,31 C62,13.8854167 48.1145833,0 31,0 Z M46.7143164,33.7222837 L24.8571475,46.6367798 C24.4553613,46.8789266 24.0133964,47 23.5714316,47 C23.1294668,47 22.687502,46.8789266 22.2857158,46.6771376 C21.4821434,46.192844 21,45.3453302 21,44.4171008 L21,18.5881085 C21,17.6598791 21.4821434,16.8123653 22.2857158,16.3280717 C23.0892882,15.8841359 24.0937537,15.8841359 24.8571475,16.3684295 L46.7143164,29.2829256 C47.5178888,29.7268614 48,30.5743752 48,31.5026046 C48,32.430834 47.5178888,33.2783479 46.7143164,33.7222837 Z" fill="#ffffff" fill-rule="nonzero"></path>
        </g>
      </svg>
    </i>
    <audio :ref="songName">
      <source :src="'/api/song/' + songName" type="audio/mp3">
      Your browser does not support the audio element.
    </audio>
	</div>
</template>

<script>
  import { EventBus } from '@/main'

	export default {
		name: 'AudioBox',
    props: {
		  name: {
		    type: String,
        required: true
      },
		  start: {
		    type: Number,
        required: false
      },
		  songName: {
		    type: String,
        required: true
      },
		  img: {
		    type: String,
        required: true
      }
    },
    data () {
      return {
        isPlaying: false,
        currentTime: 0
      }
    },
    mounted () {
		  this.currentTime = this.start || 0
		  const div = this.$refs[this.name]
      div.style.height = `200px`
      div.style.backgroundImage = `url(${require('../assets/songs/' + this.img + '.png')})`
      EventBus.$on('stop-all', this.externalPause)
    },
    methods: {
      play () {
        const audioElm = this.$refs[this.songName]
        audioElm.play()
        setTimeout(() => {
          audioElm.currentTime = this.currentTime
        }, 0)
        this.$emit('action', {
          name: this.songName
        })
        this.isPlaying = true
      },
      pause () {
        const audioElm = this.$refs[this.songName]
        audioElm.pause();
        this.currentTime = audioElm.currentTime
        this.isPlaying = false
      },
      externalPause ({name}) {
        if (name !== this.songName) {
          const audioElm = this.$refs[this.songName]
          audioElm.pause()
          audioElm.currentTime = 0
          this.isPlaying = false
        }
      }
    },
    destroyed () {
      EventBus.$off('stop-all', this.externalPause)
    }
  }
</script>

<style lang="scss">
	.audio-box {
    background-position: top left;
    background-size: cover;
	}
</style>
